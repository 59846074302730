import { Alert, Box, Stack, Typography } from "@mui/material";
import { GoogleLogin } from "@react-oauth/google";
const iPhoneImage = new URL("../public/src/iphone.png", import.meta.url);

type LandingProps = {
  onLogin: Function;
  error: string | undefined;
};
const Landing = (props: LandingProps) => {
  const { onLogin, error } = props;
  return (
    <Stack direction="column" spacing={2}>
      <Typography variant="h3">Never miss a Volo game ever again.</Typography>
      <Typography variant="subtitle1">
        With VoloCal, you can see your upcoming games directly on your phone or
        laptop calendar.
      </Typography>
      <Box
        component="img"
        width="50%"
        src={iPhoneImage.toString()}
        alt="Iphone screenshot with upcoming Volo events"
      />
      {error && <Alert severity="error">{error}</Alert>}
      <GoogleLogin
        auto_select
        text="continue_with"
        onSuccess={(credentialResponse) => {
          onLogin(credentialResponse.credential || "");
        }}
        onError={() => {
          console.log("Login Failed");
        }}
      />
    </Stack>
  );
};

export default Landing;
