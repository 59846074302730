import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Box,
  Card,
  Link,
  List,
  ListItem,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { API_URL } from "./Constants";

type VoloConnectProps = {
  refetchLoginStatus?: Function;
};

export const VoloConnect = (props: VoloConnectProps) => {
  const { refetchLoginStatus } = props;

  const mutation = useMutation({
    mutationFn: async (body: { id: string; password: string }) => {
      const res = await fetch(API_URL + "/connect", {
        method: "POST",
        credentials: "include",
        body: JSON.stringify(body),
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (res.status !== 200) {
        throw new Error("API returned non-200 code: " + res.status);
      }
      if (refetchLoginStatus) {
        refetchLoginStatus();
      }
    },
  });
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <Stack direction="column" spacing={2}>
      <PasswordSecureModal open={modalOpen} setOpen={setModalOpen} />
      <Card>
        <Box padding="2em">
          <Typography variant="h5">Connect your Volo account</Typography>
          <Typography variant="body1" marginTop="1em">
            <Link href="https://volocal.net">volocal.net</Link> will access the
            following information:
            <List
              sx={{
                listStyleType: "disc",
                pl: 4,
              }}
            >
              <ListItem style={{ display: "list-item" }}>
                Upcoming games and events
              </ListItem>
            </List>
          </Typography>
          <Box
            component="form"
            onSubmit={(e) => {
              e.preventDefault();
              const data = new FormData(e.currentTarget);
              const id = data.get("email") as string;
              const password = data.get("password") as string;
              mutation.mutate({ id, password });
            }}
            noValidate
          >
            {mutation.isError ? (
              <Alert severity="error">
                Error connecting to Volo account. Is your login or password
                correct?
              </Alert>
            ) : null}
            <TextField
              margin="normal"
              fullWidth
              id="email"
              label="Volo Email, Phone or Username"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              fullWidth
              name="password"
              label="Volo Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <Link href="#" onClick={() => setModalOpen(true)}>
              Learn how your password is secured
            </Link>
            <LoadingButton
              loading={mutation.isPending}
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </LoadingButton>
          </Box>
        </Box>
      </Card>
    </Stack>
  );
};

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const PasswordSecureModal = (props: { open: boolean; setOpen: Function }) => {
  const { open, setOpen } = props;
  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Is my Volo password secure?
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          Your Volo password is stored encrypted in our database using a
          AES256-GCM symmetric key. This key is stored in an encrypted vault in
          our hosting provider infrastructure.
        </Typography>
      </Box>
    </Modal>
  );
};
