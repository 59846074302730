import { Box, Link, Typography } from "@mui/material";

const Copyright = () => {
  return (
    <Box padding="1em">
      <Typography variant="body2" color="text.secondary" align="center">
        {"Copyright © "}
        <Link color="inherit" href="https://volocal.net/">
          VoloCal
        </Link>{" "}
        {new Date().getFullYear()}.{" "}
        <Link color="inherit" href="mailto:volocal@fastmail.com">
          Need Help?
        </Link>
      </Typography>
      <Typography variant="body2" color="text.secondary" align="center">
        VoloCal is not associated in any way with Volo.
      </Typography>
    </Box>
  );
};

export default Copyright;
