import AppleIcon from "@mui/icons-material/Apple";
import GoogleIcon from "@mui/icons-material/Google";
import MicrosoftIcon from "@mui/icons-material/Microsoft";

import {
  Box,
  Button,
  Card,
  Link,
  List,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { STRIPE_CUSTOMER_PORTAL_ID } from "./Constants";

type ManageProps = {
  volocalToken: string;
};

export const Manage = (props: ManageProps) => {
  const { volocalToken } = props;
  const webcalUrl = `webcal://api.volocal.net/cal/${volocalToken}`;
  const googleSubscribeUrl = `https://calendar.google.com/calendar/r?cid=${encodeURIComponent(
    webcalUrl
  )}`;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Stack direction="column" spacing={2}>
      <Card>
        <Box padding="2em">
          <Stack direction="column" spacing={2}>
            <Typography variant="h5">Calendar Setup</Typography>
            <Typography variant="body1">
              You're pretty much done! Just press the button below to add Volo
              events to your calendar.
            </Typography>
            <Button
              variant="contained"
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
            >
              Add Volo Calendar
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem component={Link} href={webcalUrl}>
                <ListItemIcon>
                  <AppleIcon />
                </ListItemIcon>
                <ListItemText>iCal</ListItemText>
              </MenuItem>
              <MenuItem component={Link} href={googleSubscribeUrl}>
                <ListItemIcon>
                  <GoogleIcon />
                </ListItemIcon>
                <ListItemText>Google Calendar</ListItemText>
              </MenuItem>
              <MenuItem component={Link} href={webcalUrl}>
                <ListItemIcon>
                  <MicrosoftIcon />
                </ListItemIcon>
                <ListItemText>Outlook</ListItemText>
              </MenuItem>
            </Menu>
            <TextField
              label="Manual setup URL"
              disabled
              variant="outlined"
              value={webcalUrl}
              fullWidth
            />
            <Box>
              <Typography variant="body1">Help Links:</Typography>
              <List>
                <Link href="https://support.apple.com/en-us/102301">
                  <ListItemText primary="iCloud help" />
                </Link>
                <Link href="https://support.google.com/calendar/answer/37100">
                  <ListItemText primary="Google help" />
                </Link>
                <Link href="https://support.microsoft.com/en-us/office/import-or-subscribe-to-a-calendar-in-outlook-com-cff1429c-5af6-41ec-a5b4-74f2c278e98c">
                  <ListItemText primary="Outlook.com help" />
                </Link>
              </List>
            </Box>
            <Link
              href={`https://billing.stripe.com/p/login/${STRIPE_CUSTOMER_PORTAL_ID}`}
            >
              <Button type="button" variant="outlined" color="error">
                Cancel VoloCal Subscription
              </Button>
            </Link>
          </Stack>
        </Box>
      </Card>
    </Stack>
  );
};
