import { Box, Card, Stack } from "@mui/material";
import { STRIPE_BUTTON_ID, STRIPE_PUBLISHABLE_KEY } from "./Constants";

export const Subscription = (props: { volocalToken: string }) => {
  const { volocalToken } = props;
  return (
    <Stack direction="column" spacing={2}>
      <Card>
        <Box padding="2em">
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            paddingTop="1em"
          >
            <stripe-buy-button
              client-reference-id={volocalToken}
              buy-button-id={STRIPE_BUTTON_ID}
              publishable-key={STRIPE_PUBLISHABLE_KEY}
            />
          </Box>
        </Box>
      </Card>
    </Stack>
  );
};
