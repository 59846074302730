import { Container, CssBaseline } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import useCookie from "react-use-cookie";
import { API_URL } from "./Constants";
import Copyright from "./Copyright";
import Landing from "./Landing";
import { Loading } from "./Loading";
import { Manage } from "./Manage";
import { Subscription } from "./Subscription";
import { VoloConnect } from "./VoloConnect";

const VoloCal = () => {
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <MainContainer />
      <Copyright />
    </Container>
  );
};

const MainContainer = () => {
  const [googleToken, setGoogleToken] = useCookie("gToken", "");
  const {
    isPending,
    error,
    data: loginStatus,
    refetch: refetchLoginStatus,
  } = useQuery({
    queryKey: ["status", googleToken],
    queryFn: () =>
      fetch(API_URL + "/status", {
        credentials: "include",
      }).then((res) => res.json()),
  });

  if (isPending) {
    return <Loading />;
  }

  if (!loginStatus?.loggedIn || error) {
    return (
      <Landing
        error={error?.message}
        onLogin={(token: string) =>
          setGoogleToken(token, {
            domain: "volocal.net",
            days: 365,
          })
        }
      />
    );
  }

  const { volocalToken, subscribed, voloId } = loginStatus;

  if (!voloId) {
    return <VoloConnect refetchLoginStatus={refetchLoginStatus} />;
  }

  if (!subscribed) {
    return <Subscription volocalToken={volocalToken} />;
  }

  return <Manage volocalToken={volocalToken} />;
};

export default VoloCal;
